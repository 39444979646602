// src/App.js

import React, { Component } from 'react';
import Amplify, { API, graphqlOperation, Storage } from 'aws-amplify';
import aws_exports from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react';
import { S3Image, Connect } from 'aws-amplify-react';
import { Divider, Form, Grid, Header,Icon, Input, List , Segment } from 'semantic-ui-react';
import {v4 as uuid} from 'uuid';
import {BrowserRouter as Router, Route, NavLink} from 'react-router-dom';
import VideoPlayer from 'react-video-js-player'
import { getsignedUrl, getsignedCookies }  from './graphql/mutations';
import { updateSignedurl, updateSignedcookies } from './graphql/mutations';
import { createSignedurl, createSignedcookies } from './graphql/mutations';
import { listSignedurls, listSignedcookiess } from './graphql/queries';




Amplify.configure(aws_exports);


const GetAlbum = `query GetAlbum($id: ID!, $nextTokenForPhotos: String) {
  getAlbum(id: $id) {
    id
    name
    members
    photos(sortDirection: DESC, nextToken: $nextTokenForPhotos) {
      nextToken
      items {
        thumbnail {
          width
          height
          key
        }
      }
    }
  }
}
`;

const AlbumMembers = (props) => (
  <div>
    <Header as='h4'>
      <Icon name='user circle' />
      <Header.Content>Members</Header.Content>
    </Header>
    <List bulleted>
        {props.members && props.members.map((member) => <List.Item key={member}>{member}</List.Item>)}
    </List>
  </div>
);

class AddUsernameToAlbum extends Component {
  constructor(props) {
    super(props);
    this.state = { username: '' };
  }
handleChange = (e, { name, value }) => this.setState({ [name]: value })
handleSubmit = async (event) => {
    event.preventDefault();
    const AddUsernameToAlbum = `
      mutation AddUser($username: String!, $albumId: String!) {
          addUsernameToAlbum(username: $username, albumId: $albumId) {
              id
          }
      }`;
    const result = await API.graphql(graphqlOperation(AddUsernameToAlbum, { username: this.state.username, albumId: this.props.albumId }));
    console.log(`Added ${this.state.username} to album id ${result.data.addUsernameToAlbum.id}`);
    this.setState({ username: '' });
  }
render() {
    return (
      <Input
        type='text'
        placeholder='Username'
        icon='user plus'
        iconPosition='left'
        action={{ content: 'Add', onClick: this.handleSubmit }}
        name='username'
        value={this.state.username}
        onChange={this.handleChange}
      />
    )
  }
}




class PhotosList extends React.Component {
  photoItems() {
    return this.props.photos.map(photo =>
      <S3Image
        key={photo.thumbnail.key}
        imgKey={photo.thumbnail.key.replace('public/', '')}
        style={{display: 'inline-block', 'paddingRight': '5px'}}
      />
    );
  }
  render() {
    return (
      <div>
        <Divider hidden />
        {this.photoItems()}
      </div>
    );
  }
}

class S3ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { uploading: false }
  }

  onChange = async (e) => {
    const file = e.target.files[0];
    const fileName = uuid();
    this.setState({uploading: true});
    const result = await Storage.put(
      fileName,
      file,
      {
        customPrefix: { public: 'uploads/' },
        metadata: { albumid: this.props.albumId }
      }
    );
    console.log('Uploaded file: ', result);
    this.setState({uploading: false});
  }

  render() {
      return (
        <div>
          <Form.Button
            onClick={() => document.getElementById('add-image-file-input').click()}
            disabled={this.state.uploading}
            icon='file image outline'
            content={ this.state.uploading ? 'Uploading...' : 'Add Image' }
          />
          <input
            id='add-image-file-input'
            type="file"
            accept='image/*'
            onChange={this.onChange}
            style={{ display: 'none' }}
          />
        </div>
      );
    }
  }

function makeComparator(key, order='asc') {
  return (a, b) => {
    if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

    const aVal = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
    const bVal = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (aVal > bVal) comparison = 1;
    if (aVal < bVal) comparison = -1;

    return order === 'desc' ? (comparison * -1) : comparison
  };
}

class VideoChristmas extends Component {

  player = {}
  constructor() {
    super();
    this.state = {
     
      sources: {
        christmas: 'https://kangavideos.kangapp.net/christmass.mp4',
      }
    };
  }

  onPlayerReady(player) {
    console.log('Player 1 is ready: ', player);
    
    this.player = player;

    //player.withCredentials(true);
    //player.addClass('vjs-4-3');

    this.player.fluid(true);
}  
  
  render() {  
    return (
      <div> 
         <Segment>
         <Header as='h3' color='pink'>Kanga on Christmas Eve</Header>  
         <VideoPlayer
              controls={true}
              src={this.state.sources.christmas}
              // width="720"
              // height="420"
              onReady={this.onPlayerReady.bind(this)} />
         </Segment>
      </div>
      
      )
   }
}

class VideoMannheim extends Component {

  player = {}
  constructor() {
    super();
    this.state = {
     
      sources: {
       
        wasserturm: 'https://kangavideos.kangapp.net/wasserturm.mp4'
      }
    };
  }

  onPlayerReady(player) {
    console.log('Player 2 is ready: ', player);
    
    this.player = player;
    //player.withCredentials(true);
    this.player.fluid(true);
}  
  
  render() {  
    return (
      <div> 
         <Segment>
         <Header as='h3' color='pink'>Kanga in Mannheim</Header>  
         <VideoPlayer
              controls={true}
              src={this.state.sources.wasserturm}
              // width="720"
              // height="420"
              onReady={this.onPlayerReady.bind(this)} />
         </Segment>
      </div>
      
      )
   }
}

class VideoAbudhabi extends Component {

  player = {}

  constructor() {
    super();
    this.state = {
     
      sources: {
       
        abudhabi: 'https://kangavideos.kangapp.net/abudhabi.mp4'
      }
    };
  }

  onPlayerReady(player) {
    console.log('Player 3 is ready: ', player);
    
    this.player = player;

    // player.src({
    //   fill: true,
    //   withCredentials:  true,
    // });
    //player.withCredentials(true);
    
    this.player.fluid(true);
    

    //this.player.fluid(true);
}  
  
  render() {  
    return (
      <div> 
         <Segment>
         <Header as='h3' color='pink'>Kanga in Abu Dhabi</Header>  
         <VideoPlayer
              controls={true}
              src={this.state.sources.abudhabi}
              // width="720"
              // height="420"
              onReady={this.onPlayerReady.bind(this)} />
         </Segment>
      </div>
      
      )
   }
}

class VideoLoader extends React.Component {

  constructor() {
    super();
    this.state = {
      loading: 'initial',
      data:'',
      activationKey:'',
      sources: {
        christmas: 'https://kangavideos.kangapp.net/christmass.mp4',
        corona: 'https://kangavideos.kangapp.net/MVI_3972.MP4',
        wasserturm: 'https://kangavideos.kangapp.net/wasserturm.mp4'
      },
      // signedurls:'',
      signedcookies: '',
      id: ''
    };
    console.log('constructor')
  }

  async componentDidMount() {
    try {
        // Read and set existing signed url from the DB
      // const apiData = await API.graphql(graphqlOperation(listSignedurls))     
      // const signedurls = apiData.data.listSignedurls.items[0].url
      // const id = apiData.data.listSignedurls.items[0].id
      // this.setState({ signedurls })

        // Read and set existing signed cookies from  the DB
      const apiData = await API.graphql(graphqlOperation(listSignedcookiess))     
      const signedcookies = apiData.data.listSignedcookiess.items[0].cookies

      this.setCookie(signedcookies.keypairid, signedcookies.signature, signedcookies.policy);

      const id = apiData.data.listSignedcookiess.items[0].id
      this.setState({ signedcookies })

      this.setState({ id })
      //console.log('signedcookies: ', this.state.signedcookies)
      //console.log('id: ', this.state.id)
      this.setState({loading: 'false'})
    } catch (err) {
      console.log('error: ', err)
    }
  }
     

     handleChange = (event) => {
      let change = {};
      change[event.target.name] = event.target.value;
      this.setState(change);
    }
  
    handleSubmit = async (event) => { 
      event.preventDefault();
      console.info(`Pass over activation code and open the video`);
      //console.log(this.state.activationKey);
      if(this.state.activationKey === 'KANGA_01' || this.state.activationKey === 'KANGA_10' || this.state.activationKey === 'KANGA_60') {
        //console.log(this.state.activationKey);
        this.setState({loading: 'true'});
        this.addData();
      }
      else {
        this.setState({loading: 'error'});
      }
      
    }
  
   setCookie(keyPairId,signature, policy ) {

    
      const domainToSet = '.kangapp.net';

      document.cookie = "CloudFront-Key-Pair-Id=" + keyPairId + ";domain=" + domainToSet;
      document.cookie = "CloudFront-Signature=" + signature + ";domain=" + domainToSet;
      document.cookie = "CloudFront-Policy=" + policy + ";domain=" + domainToSet;

      //console.log("cookiesWereSet:", document.cookie)
  }
   
  
  async addData() {

    const entry = {expirationTime: this.state.activationKey}

      //Get signed urls/cookies from Lambda function over graphql command
    //const signedURL = await API.graphql(graphqlOperation(getsignedUrl,entry))
    //const url = signedURL.data.getsignedURL;
    const signedCookies = await API.graphql(graphqlOperation(getsignedCookies,entry))  
    const cookies = signedCookies.data.getsignedCookies; 

    //console.log("Cookies", signedCookies );

    

    var length = cookies.length;
    var posKey = cookies.indexOf("CloudFront-Key-Pair-Id");
    var posSignature = cookies.indexOf("CloudFront-Signature");
    var posPolicy = cookies.indexOf("CloudFront-Policy");

    var policy = cookies.substring(posPolicy+18, posKey-2);
    var keyPairId = cookies.substring(posKey+23, posSignature-2);
    var signature = cookies.substring(posSignature+21, length-1);

    this.setCookie(keyPairId, signature,policy);

    //console.log("cookiesWereSet:", document.cookie)
  
    //const urlCookies = 'https://kangavideos.kangapp.net/MVI_3972.MP4';
    
    //if(this.state.signedurls)
    if(this.state.signedcookies)
    {
      console.log('id', this.state.id)
      const updatedCookies = await API.graphql(graphqlOperation(updateSignedcookies,{input:{id: this.state.id, cookies: {signature: signature, policy: policy, keypairid: keyPairId}}}))
      //const updatedURL = await API.graphql(graphqlOperation(updateSignedurl,{input: {id: this.state.id, url: url}}))
      //console.log('New updated cookies:', updatedCookies.data)
    }
    else {
      
      const createdCookies = await API.graphql(graphqlOperation(createSignedcookies,{input:{cookies: {signature: signature, policy: policy, keypairid: keyPairId}}}))
      //const createdURL = await API.graphql(graphqlOperation(createSignedurl,{input: {url: url}}))
      //console.log('New created cookies:', createdCookies.data)
    }
    //signedurls: url
    this.setState({
      loading: 'false'
    });
}


//   onPlayerReady(player) {
//     console.log('Player 1 is ready: ', player);
    

//     player.src({
//       fill: true,
//       withCredentials:  true,
//     });
// }   

onPlayerReady2(player) {
  console.log('Player 2 is ready: ', player);
  

  player.src({
    fill: true,
    withCredentials:  true,
  });
}  

onPlayerReady3(player) {
  console.log('Player 3 is ready: ', player);
  

  player.src({
    fill: true,
    withCredentials:  true,
  });
}  
  render() {
    
    if (this.state.loading === 'initial') {
      
      return (
   
        <div>  
            <Header as='h3' color='pink'>KANGA Videos</Header>  
            <Segment color='pink'>
              <Input 
              type="text" 
              placeholder="Provide activation key"
              action={{ content: 'Activate', onClick: this.handleSubmit }}
              name='activationKey'
              value={this.state.activationKey}
              onChange={this.handleChange}/>  
            </Segment>
        </div>  
       );
    }

    if (this.state.loading === 'true') {
     
      return <h2>Loading...</h2>;
    }

    if (this.state.loading === 'error') {
      
      return (
      <div>
        <Header as='h3' color='pink'>KANGA Videos</Header>  
        
        <Segment color='pink'>
        <Input 
          type="text" 
          placeholder="Provide activation key"
          action={{ content: 'Activate', onClick: this.handleSubmit }}
          name='activationKey'
          value={this.state.activationKey}
          onChange={this.handleChange} />
        </Segment>
        <h2>Wrong Activation Key!</h2>;
      </div> )
    }
    
    return (
      <div>
       
        <Header as='h3' color='pink'>KANGA Videos</Header>  
        
        <Segment color='pink'>
          <Input 
            type="text" 
            placeholder="Provide activation key"
            action={{ content: 'Activate', onClick: this.handleSubmit }}
            name='activationKey'
            value={this.state.activationKey}
            onChange={this.handleChange} />
        </Segment>
        
        <Segment color='pink'>     
          <List divided relaxed>
            <List.Item>
              <NavLink to={`/christmas`}> Kanga on Christmas Eve</NavLink>
            </List.Item>
            <List.Item>
              <NavLink to={`/abudhabi`}>  Kanga in Abu Dhabi</NavLink>
            </List.Item>
            <List.Item>
              <NavLink to={`/mannheim`}> Kanga in Mannheim</NavLink>
            </List.Item>
          </List>   
        </Segment> 
        {/* <Segment color='pink'>
          Kanga on Christmas Eve
          <VideoPlayer
              controls={true}
              src={this.state.sources.christmas}
             // width="720"
             // height="420"
              onReady={this.onPlayerReady.bind(this)} />
           
        </Segment> 

         <Segment color='pink'>
          Kanga in Abu Dhabi
          <VideoPlayer
              controls={true}
              src={this.state.sources.corona}
             // width="720"
             // height="420"
              onReady={this.onPlayerReady2.bind(this)} />
        </Segment>  
        <Segment color='pink'>
          Kanaga in Mannheim
          <VideoPlayer
              controls={true}
              src={this.state.sources.wasserturm}
             // width="720"
             // height="420"
              onReady={this.onPlayerReady3.bind(this)} />
        </Segment>     */}
     </div>
      )
   }
}


class NewAlbum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      albumName: ''
     };
   }

  handleChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const NewAlbum = `mutation NewAlbum($name: String!) {
      createAlbum(input: {name: $name}) {
        id
        name
      }
    }`;

    const result = await API.graphql(graphqlOperation(NewAlbum, { name: this.state.albumName }));
    console.info(`Created album with id ${result.data.createAlbum.id}`);
  }

  render() {
    return (
      <Segment>
        <Header as='h3'>Add a new album</Header>
         <Input
          type='text'
          placeholder='New Album Name'
          icon='plus'
          iconPosition='left'
          action={{ content: 'Create', onClick: this.handleSubmit }}
          name='albumName'
          value={this.state.albumName}
          onChange={this.handleChange}
         />
        </Segment>
      )
   }
}

const SubscribeToNewAlbums = `
  subscription OnCreateAlbum {
    onCreateAlbum {
      id
      name
    }
  }
`;

class AlbumsList extends React.Component {
  albumItems() {
    return this.props.albums.sort(makeComparator('name')).map(album =>
      <List.Item key={album.id}>
        <NavLink to={`/albums/${album.id}`}>{album.name}</NavLink>
      </List.Item>
    );
  }

  render() {
    return (
      <Segment>
        <Header as='h3'>My Albums</Header>
        <List divided relaxed>
          {this.albumItems()}
        </List>
      </Segment>
    );
  }
}

const ListAlbums = `query ListAlbums {
    listAlbums(limit: 9999) {
        items {
            id
            name
        }
    }
}`;

class AlbumsListLoader extends React.Component {
  onNewAlbum = (prevQuery, newData) => {
          // When we get data about a new album,
          // we need to put in into an object
          // with the same shape as the original query results,
          // but with the new data added as well
          let updatedQuery = Object.assign({}, prevQuery);
          updatedQuery.listAlbums.items = prevQuery.listAlbums.items.concat([newData.onCreateAlbum]);
          return updatedQuery;
      }


  render() {
    return (
      <Connect query={graphqlOperation(ListAlbums)}
      // 2b. NEW: Listen to our
      // SubscribeToNewAlbums subscription
      subscription={graphqlOperation(SubscribeToNewAlbums)}
      // 2c. NEW: Handle new subscription messages
      onSubscriptionMsg={this.onNewAlbum}
      >
        {({ data, loading, errors }) => {
          if (loading) { return <div>Loading...</div>; }
          if (!data.listAlbums) return;

          return <AlbumsList albums={data.listAlbums.items} />;
        }}
      </Connect>
    );
  }
}

class AlbumDetailsLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextTokenForPhotos: null,
      hasMorePhotos: true,
      album: null,
      loading: true
    }
  }
  async loadMorePhotos() {
    if (!this.state.hasMorePhotos) return;
    this.setState({ loading: true });
    const { data } = await API.graphql(graphqlOperation(GetAlbum, {id: this.props.id, nextTokenForPhotos: this.state.nextTokenForPhotos}));
    let album;
    if (this.state.album === null) {
      album = data.getAlbum;
    } else {
      album = this.state.album;
      album.photos.items = album.photos.items.concat(data.getAlbum.photos.items);
    }
    this.setState({
      album: album,
      loading: false,
      nextTokenForPhotos: data.getAlbum.photos.nextToken,
      hasMorePhotos: data.getAlbum.photos.nextToken !== null
    });
  }
  componentDidMount() {
    this.loadMorePhotos();
  }
  render() {
    return <AlbumDetails loadingPhotos={this.state.loading} album={this.state.album} loadMorePhotos={this.loadMorePhotos.bind(this)} hasMorePhotos={this.state.hasMorePhotos}/>;
  }
}


class AlbumDetails extends Component {
  render() {
    if (!this.props.album) return 'Loading album...';
    return (
      <Segment>
        <Header as='h3'>{this.props.album.name}</Header>
        <Segment.Group>
          <Segment>
            <AlbumMembers members={this.props.album.members} />
          </Segment>
          <Segment basic>
            <AddUsernameToAlbum albumId={this.props.album.id} />
          </Segment>
        </Segment.Group>
        <S3ImageUpload albumId={this.props.album.id}/>
        <PhotosList photos={this.props.album.photos.items} />
        {
          this.props.hasMorePhotos &&
          <Form.Button
            onClick={this.props.loadMorePhotos}
            icon='refresh'
            disabled={this.props.loadingPhotos}
            content={this.props.loadingPhotos ? 'Loading...' : 'Load more photos'}
          />
        }
      </Segment>
    )
  }
}

class App extends Component {
  render() {
   return (
     <Router>
       <Grid padded>
         <Grid.Column>
           {/* <Route path="/" exact component={NewAlbum}/>
           <Route path="/" exact component={AlbumsListLoader}/> */}
           <Route path="/" exact component={VideoLoader}/>
           <Route 
             path="/christmas" 
             render={ () => <div><NavLink to='/'>Back to KANGA Videos</NavLink></div> }
             />
           <Route 
             path="/christmas" 
             exact component={VideoChristmas}
            />
            <Route 
             path="/mannheim" 
             render={ () => <div><NavLink to='/'>Back to KANGA Videos</NavLink></div> }
             />
           <Route 
             path="/mannheim" 
             exact component={VideoMannheim}
            />
            <Route 
             path="/abudhabi" 
             render={ () => <div><NavLink to='/'>Back to KANGA Videos</NavLink></div> }
             />
           <Route 
             path="/abudhabi" 
             exact component={VideoAbudhabi}
            />

           {/* <Route
             path="/albums/:albumId"
             render={ () => <div><NavLink to='/'>Back to Albums list</NavLink></div> }
           />
           <Route
             path="/albums/:albumId"
             render={ props => <AlbumDetailsLoader id={props.match.params.albumId}/> }
           />  */}
         </Grid.Column>
       </Grid>
     </Router>
   );
 }
}


export default withAuthenticator(App, {includeGreetings: true, usernameAttributes: 'email'});


