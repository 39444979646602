/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getsignedUrl = /* GraphQL */ `
  mutation GetsignedUrl($expirationTime: String) {
    getsignedURL(expirationTime: $expirationTime)
  }
`;
export const getsignedCookies = /* GraphQL */ `
  mutation GetsignedCookies($expirationTime: String) {
    getsignedCookies(expirationTime: $expirationTime)
  }
`;
export const createAlbum = /* GraphQL */ `
  mutation CreateAlbum(
    $input: CreateAlbumInput!
    $condition: ModelAlbumConditionInput
  ) {
    createAlbum(input: $input, condition: $condition) {
      id
      name
      owner
      photos {
        items {
          id
          bucket
        }
        nextToken
      }
    }
  }
`;
export const updateAlbum = /* GraphQL */ `
  mutation UpdateAlbum(
    $input: UpdateAlbumInput!
    $condition: ModelAlbumConditionInput
  ) {
    updateAlbum(input: $input, condition: $condition) {
      id
      name
      owner
      photos {
        items {
          id
          bucket
        }
        nextToken
      }
    }
  }
`;
export const deleteAlbum = /* GraphQL */ `
  mutation DeleteAlbum(
    $input: DeleteAlbumInput!
    $condition: ModelAlbumConditionInput
  ) {
    deleteAlbum(input: $input, condition: $condition) {
      id
      name
      owner
      photos {
        items {
          id
          bucket
        }
        nextToken
      }
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      id
      album {
        id
        name
        owner
        photos {
          nextToken
        }
      }
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      id
      album {
        id
        name
        owner
        photos {
          nextToken
        }
      }
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      id
      album {
        id
        name
        owner
        photos {
          nextToken
        }
      }
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
    }
  }
`;
export const createSignedurl = /* GraphQL */ `
  mutation CreateSignedurl(
    $input: CreateSignedurlInput!
    $condition: ModelSignedurlConditionInput
  ) {
    createSignedurl(input: $input, condition: $condition) {
      id
      usr
      url
    }
  }
`;
export const updateSignedurl = /* GraphQL */ `
  mutation UpdateSignedurl(
    $input: UpdateSignedurlInput!
    $condition: ModelSignedurlConditionInput
  ) {
    updateSignedurl(input: $input, condition: $condition) {
      id
      usr
      url
    }
  }
`;
export const deleteSignedurl = /* GraphQL */ `
  mutation DeleteSignedurl(
    $input: DeleteSignedurlInput!
    $condition: ModelSignedurlConditionInput
  ) {
    deleteSignedurl(input: $input, condition: $condition) {
      id
      usr
      url
    }
  }
`;
export const createSignedcookies = /* GraphQL */ `
  mutation CreateSignedcookies(
    $input: CreateSignedcookiesInput!
    $condition: ModelSignedcookiesConditionInput
  ) {
    createSignedcookies(input: $input, condition: $condition) {
      id
      usr
      cookies {
        signature
        policy
        keypairid
      }
    }
  }
`;
export const updateSignedcookies = /* GraphQL */ `
  mutation UpdateSignedcookies(
    $input: UpdateSignedcookiesInput!
    $condition: ModelSignedcookiesConditionInput
  ) {
    updateSignedcookies(input: $input, condition: $condition) {
      id
      usr
      cookies {
        signature
        policy
        keypairid
      }
    }
  }
`;
export const deleteSignedcookies = /* GraphQL */ `
  mutation DeleteSignedcookies(
    $input: DeleteSignedcookiesInput!
    $condition: ModelSignedcookiesConditionInput
  ) {
    deleteSignedcookies(input: $input, condition: $condition) {
      id
      usr
      cookies {
        signature
        policy
        keypairid
      }
    }
  }
`;
