/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAlbum = /* GraphQL */ `
  query GetAlbum($id: ID!) {
    getAlbum(id: $id) {
      id
      name
      owner
      photos {
        items {
          id
          bucket
        }
        nextToken
      }
    }
  }
`;
export const listAlbums = /* GraphQL */ `
  query ListAlbums(
    $filter: ModelAlbumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlbums(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        photos {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      id
      album {
        id
        name
        owner
        photos {
          nextToken
        }
      }
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        album {
          id
          name
          owner
        }
        bucket
        fullsize {
          key
          width
          height
        }
        thumbnail {
          key
          width
          height
        }
      }
      nextToken
    }
  }
`;
export const getSignedurl = /* GraphQL */ `
  query GetSignedurl($id: ID!) {
    getSignedurl(id: $id) {
      id
      usr
      url
    }
  }
`;
export const listSignedurls = /* GraphQL */ `
  query ListSignedurls(
    $filter: ModelSignedurlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSignedurls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usr
        url
      }
      nextToken
    }
  }
`;
export const getSignedcookies = /* GraphQL */ `
  query GetSignedcookies($id: ID!) {
    getSignedcookies(id: $id) {
      id
      usr
      cookies {
        signature
        policy
        keypairid
      }
    }
  }
`;
export const listSignedcookiess = /* GraphQL */ `
  query ListSignedcookiess(
    $filter: ModelSignedcookiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSignedcookiess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usr
        cookies {
          signature
          policy
          keypairid
        }
      }
      nextToken
    }
  }
`;
