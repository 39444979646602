// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:ac6dcc04-a5e6-4b74-9cd1-87712b4862b4",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_a1SKxU4A8",
    "aws_user_pools_web_client_id": "6hbfcvin1tlc1qhs3r6oop7fg7",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://d3nar6ywbfdxrfbyoqkwtusbym.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "kanga4be683424ddf4f029fe677370000257100346-kangaenv",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_content_delivery_bucket": "kanga-20200330162019-hostingbucket-kangaenv",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://dcbgmn0hy6vlh.cloudfront.net"
};


export default awsmobile;
